import request from '../util/request';

const apis = {
    addComment() {
        return request({
            url: "/V1/addComment",
            method: 'post',
        })
    }

}
export default apis