var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"complaint"},[_c('div',{staticClass:"complaint__l"},[_c('div',{staticClass:"complaint__l_nav"},[_vm._m(0),_c('div',{staticClass:"complaint__l_nav-b"},[_c('div',{staticClass:"selct"},[_c('el-cascader',{attrs:{"props":_vm.props,"options":_vm.listAZ,"placeholder":"厂商名称"},on:{"change":_vm.handleChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(_vm.titleType == 1)?_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.to('')}}},[_vm._v(" 前往点评 ")]):_vm._e(),(_vm.titleType == 2)?_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.to('')}}},[_vm._v(" 前往投诉 ")]):_vm._e()],1)]),_c('div',{staticClass:"complaint__l_nav mt"},[_c('div',{staticClass:"complaint__l_nav-t"},[_c('div',{staticClass:"complaint__l_nav-t-ttitle"},[_vm._v(" "+_vm._s(_vm.titleType == 1 ? "点评公示" : "投诉公示")+" ")])]),_c('div',{staticClass:"complaint__l_nav-b"},_vm._l((_vm.newcommentList),function(item,index){return _c('div',{key:index,staticClass:"comment"},[_c('div',{staticClass:"comment_header"},[_c('div',{staticClass:"username"},[_c('el-avatar',{attrs:{"size":40,"src":item.memberIcon}}),_c('span',{staticClass:"user"},[_vm._v(_vm._s(item.memberName))])],1),_c('div',{staticClass:"releaseTime"},[_vm._v(_vm._s(item.splitTime))])]),_c('div',{staticClass:"comment_center"},[_c('div',{staticClass:"stars"},[_c('el-rate',{attrs:{"disabled-void-icon-class":"iconfont icon-start","icon-classes":[
                  'iconfont icon-start',
                  'iconfont icon-start',
                  'iconfont icon-start' ],"disabled":"","colors":['#FF3355', '#FF3355', '#FF3355']},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(item.score),callback:function ($$v) {_vm.$set(item, "score", $$v)},expression:"item.score"}})],1),_c('div',{staticClass:"comment_center-r"},[_vm._v(" "+_vm._s(item.catName || "产品类型")+"/"+_vm._s(item.apparatusName || "产品名称")+"/"+_vm._s(item.apparatusType || "产品型号")+" ")])]),_c('div',{staticClass:"comment_text"},[_vm._v(" "+_vm._s(item.text)+" "),_c('span',{staticClass:"comment_text-more",on:{"click":function($event){return _vm.toInfo(item)}}},[_vm._v(" 点击查看完整评论 "),_c('i',{staticClass:"iconfont icon-more"})])]),_c('div',{staticClass:"comment_img"},_vm._l((item.file),function(i,index){return _c('div',{key:index},[_c('img',{attrs:{"src":i.filePath,"alt":""}})])}),0),_c('div',{staticClass:"comment_line"})])}),0)]),(_vm.newcommentList)?_c('div',{staticClass:"button"},[(_vm.total > _vm.newcommentList.length)?_c('button',{on:{"click":_vm.more}},[_vm._v(" 加载更多 ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"complaint__r"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"topinfo__r_contanier"},[_c('div',{staticClass:"topinfo__r_header"},[_c('span',[_vm._v(" "+_vm._s(_vm.titleType == 1 ? "点评榜TOP10" : "投诉榜TOP10"))])]),_vm._l((_vm.topList),function(item,index){return _c('div',{key:index,staticClass:"topinfo__r_content"},[(index == 0)?_c('div',{staticClass:"content-img top1"},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.tolibInfo(
                '/instrumentlib/info',
                item.apparatusId,
                item.apparatusCatId
              )}}},[(item.apparatusFileId)?_c('img',{attrs:{"src":item.apparatusFileId,"alt":""}}):_vm._e(),(!item.apparatusFileId)?_c('img',{attrs:{"src":require("../assets/test.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"content-title top1"},[_vm._v(_vm._s(item.apparatusType))]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(item.apparatusScore)+"分 ")]),_c('div',{staticClass:"topIcon"},[(index == 0)?_c('img',{attrs:{"src":require("../assets/t1.png"),"alt":""}}):_vm._e()])])]):_vm._e(),(index != 0)?_c('div',{staticClass:"content-img"},[_c('div',{staticClass:"box2",on:{"click":function($event){return _vm.tolibInfo(
                '/instrumentlib/info',
                item.apparatusId,
                item.apparatusCatId
              )}}},[(item.apparatusFileId)?_c('img',{attrs:{"src":item.apparatusFileId,"alt":""}}):_vm._e(),(!item.apparatusFileId)?_c('img',{attrs:{"src":require("../assets/test.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"content-title2"},[_vm._v(" "+_vm._s(item.apparatusType)+_vm._s(item.apparatusName)+" ")]),_c('div',{staticClass:"content-count"},[_c('img',{attrs:{"src":require("../assets/commentScore.png")}}),_vm._v(" "+_vm._s(item.apparatusScore)+"分 ")]),_c('div',{staticClass:"topIcon"},[(index == 1)?_c('img',{attrs:{"src":require("../assets/t2.png"),"alt":""}}):_vm._e(),(index == 2)?_c('img',{attrs:{"src":require("../assets/t3.png"),"alt":""}}):_vm._e()])])]):_vm._e()])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"complaint__l_nav-t"},[_c('div',{staticClass:"complaint__l_nav-t-ttitle"},[_vm._v("按厂商型号搜索")])])}]

export { render, staticRenderFns }