<template>
  <div class="complaint">
    <div class="complaint__l">
      <div class="complaint__l_nav">
        <div class="complaint__l_nav-t">
          <div class="complaint__l_nav-t-ttitle">按厂商型号搜索</div>
        </div>
        <div class="complaint__l_nav-b">
          <div class="selct">
            <!-- <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.firmId"
                :label="item.firmName"
                :value="item.firmName"
              >
              </el-option>
            </el-select> -->
            <el-cascader
              v-model="value"
              :props="props"
              :options="listAZ"
              @change="handleChange"
              placeholder="厂商名称"
            ></el-cascader>
          </div>

          <el-button type="primary" plain v-if="titleType == 1" @click="to('')">
            前往点评
          </el-button>
          <el-button type="primary" plain v-if="titleType == 2" @click="to('')">
            前往投诉
          </el-button>
        </div>
      </div>

      <div class="complaint__l_nav mt">
        <div class="complaint__l_nav-t">
          <div class="complaint__l_nav-t-ttitle">
            {{ titleType == 1 ? "点评公示" : "投诉公示" }}
          </div>
        </div>
        <div class="complaint__l_nav-b">
          <div
            class="comment"
            v-for="(item, index) in newcommentList"
            :key="index"
          >
            <!-- <div class="comment_tite">优质点评</div> -->
            <div class="comment_header">
              <!-- <div class="usermsg"> -->

              <div class="username">
                <el-avatar :size="40" :src="item.memberIcon"></el-avatar
                ><span class="user">{{ item.memberName }}</span>
              </div>
              <div class="releaseTime">{{ item.splitTime }}</div>
              <!-- </div> -->
            </div>
            <div class="comment_center">
              <div class="stars">
                <el-rate
                  @click.native.stop
                  v-model="item.score"
                  disabled-void-icon-class="iconfont icon-start"
                  :icon-classes="[
                    'iconfont icon-start',
                    'iconfont icon-start',
                    'iconfont icon-start',
                  ]"
                  disabled
                  :colors="['#FF3355', '#FF3355', '#FF3355']"
                >
                </el-rate>
              </div>
              <div class="comment_center-r">
                {{ item.catName || "产品类型" }}/{{
                  item.apparatusName || "产品名称"
                }}/{{ item.apparatusType || "产品型号" }}
              </div>
            </div>
            <div class="comment_text">
              {{ item.text }}
              <span class="comment_text-more" @click="toInfo(item)">
                点击查看完整评论
                <i class="iconfont icon-more"></i>
              </span>
            </div>
            <div class="comment_img">
              <div v-for="(i, index) in item.file" :key="index">
                <img :src="i.filePath" alt="" />
              </div>
            </div>
            <div class="comment_line"></div>
            <!-- <div class="comment_count">
              <el-button type="text">1</el-button>
              <el-button type="text">0</el-button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="button" v-if="newcommentList">
        <button @click="more" v-if="total > newcommentList.length">
          加载更多
        </button>
      </div>
    </div>

    <div class="complaint__r">
      <div class="line"></div>
      <div class="topinfo__r_contanier">
        <div class="topinfo__r_header">
          <span> {{ titleType == 1 ? "点评榜TOP10" : "投诉榜TOP10" }}</span>
        </div>
        <div
          class="topinfo__r_content"
          v-for="(item, index) in topList"
          :key="index"
        >
          <div class="content-img top1" v-if="index == 0">
            <div
              class="box"
              @click="
                tolibInfo(
                  '/instrumentlib/info',
                  item.apparatusId,
                  item.apparatusCatId
                )
              "
            >
              <img
                v-if="item.apparatusFileId"
                :src="item.apparatusFileId"
                alt=""
              />
              <img
                v-if="!item.apparatusFileId"
                src="../assets/test.png"
                alt=""
              />
              <div class="content-title top1">{{ item.apparatusType }}</div>
              <span class="count"> {{ item.apparatusScore }}分 </span>
              <div class="topIcon">
                <img v-if="index == 0" src="../assets/t1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="content-img" v-if="index != 0">
            <div
              class="box2"
              @click="
                tolibInfo(
                  '/instrumentlib/info',
                  item.apparatusId,
                  item.apparatusCatId
                )
              "
            >
              <img
                v-if="item.apparatusFileId"
                :src="item.apparatusFileId"
                alt=""
              />
              <img
                v-if="!item.apparatusFileId"
                src="../assets/test.png"
                alt=""
              />
              <div class="content-title2">
                {{ item.apparatusType }}{{ item.apparatusName }}
              </div>
              <div class="content-count">
                <img src="../assets/commentScore.png" />
                {{ item.apparatusScore }}分
              </div>
              <div class="topIcon">
                <img v-if="index == 1" src="../assets/t2.png" alt="" />
                <img v-if="index == 2" src="../assets/t3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import complaint from "../api/complaint";
import comment from "../api/comment";
import lib from "../api/lib";
import api from "../api/api";
import cnchar from "cnchar";
export default {
  data() {
    let _self = this;
    return {
      value1: 1,
      value: "",
      titleType: "",
      options: [],
      commentList: "",
      topList: [],
      props: {
        lazy: true,
        // checkStrictly:true,
        lazyLoad: _self.cascaderLazyload,
      },
      listAZ: [],
      apparatusId: [],
      total: "",
      pages: "",
    };
  },
  computed: {
    newcommentList: {
      get() {
        for (const i in this.commentList) {
          let data = this.commentList[i];
          if (!data.createTime) {
            return;
          }
          let dateTimeStamp = new Date(data.createTime).getTime();
          let minute = 1000 * 60;
          let hour = minute * 60;
          let day = hour * 24;

          let month = day * 30;
          let year = month * 12;
          let now = new Date().getTime();
          let diffValue = now - dateTimeStamp;
          let result = "";
          if (diffValue < 0) {
            return;
          }
          let monthC = diffValue / month;
          let weekC = diffValue / (7 * day);
          let dayC = diffValue / day;
          let hourC = diffValue / hour;
          let minC = diffValue / minute;
          let yearC = diffValue / year;
          if (yearC >= 1) {
            return "" + parseInt(yearC) + "年前";
          }
          if (monthC >= 1) {
            result = "" + parseInt(monthC) + "月前";
          } else if (weekC >= 1) {
            result = "" + parseInt(weekC) + "周前";
          } else if (dayC >= 1) {
            result = "" + parseInt(dayC) + "天前";
          } else if (hourC >= 1) {
            result = "" + parseInt(hourC) + "小时前";
          } else if (minC >= 1) {
            result = "" + parseInt(minC) + "分钟前";
          } else {
            result = "刚刚";
          }
          data.time = result;
          data.splitTime = data.createTime.split(" ")[0];

          data.score = parseFloat(data.score).toFixed(1) / 2;

          data.text = this.titleType == 1 ? data.description : data.description;
          if (data.text.length > 39) {
            data.text = data.text.slice(0, 39) + "...";
          }
        }

        return this.commentList;
      },
      set() {},
    },
    starClass() {
      //stars
      let result = [];
      for (const i in this.commentList) {
        let data = this.commentList[i];

        let score = Math.floor(data.score * 2) / 2;
        let starhalf = score % 1 != 0 ? true : false;
        let fullstar = Math.floor(score);
        for (let c = 0; c < fullstar; c++) {
          //放全星
          result.push("starOn");
        }
        if (starhalf) {
          //放半星
          result.push("starHalf");
        }
        if (5 - fullstar != 0) {
          for (let c = 0; c < 5 - fullstar - 1; c++) {
            result.push("starOff");
          }
        }
      }

      return result;
    },
  },
  watch: {
    $route(val) {
      this.titleChange();
      this.top();
      this.handleChange();
      
    },
  },
  created() {
    this.titleChange();
    this.top();
    // this.searchApparatus();
    this.handleChange();
    this.getFirm();
    // this.commentApparatus();
  },
  methods: {
    tolibInfo(path, id, catId) {
      this.$router.push(`${path}/${id}/${catId}`);
    },
    to() {
      let path = this.titleType == 1 ? "/comment/commit" : "/complaint/commit";

      if (this.value != "") {
        this.$router.push(`${path}/${this.titleType}/${this.apparatusId}`);
      } else {
        this.$message("请选择产品");
      }
    },
    toInfo(item) {
      let code = this.titleType == 1 ? item.commentCode : item.complainCode;
      let path = this.titleType == 1 ? "/comment/info" : "/complaint/info";
      let id = item.apparatusId;
      this.$router.push(`${path}/${this.titleType}/${code}/${id}`);
    },
    titleChange() {
      this.titleType = this.$route.params.type;
    },
    init() {},
    addComplain() {
      complaint.addComplain().then((res) => {});
    },
    addComment() {
      comment.addComment().then((res) => {});
    },

    commentApparatus(id) {
      let data = {
        apparatusId: id,
        page: 1,
      };
      lib.commentApparatus(data).then((res) => {
        let data = res.data.data.data;
        this.total = res.data.data.total;
        this.commentList = data;
      });
    },
    complainApparatus(id) {
      let data = {
        apparatusId: id,
        page: 1,
      };
      lib.complainApparatus(data).then((res) => {
        let data = res.data.data.data;
        this.commentList = data;
      });
    },
    handleChange(value) {
      let id = value ? value[value.length - 1] : null;
      if (this.titleType == 1) {
        this.commentApparatus(id);
      } else {
        this.complainApparatus(id);
      }
    },
    top() {
      let type = "";
      if (this.titleType == 1) {
        type = "comment";
      } else {
        type = "complain";
      }
      this.indexTopApparatus(type);
    },
    indexTopApparatus(type) {
      let data = {
        type: type,
      };
      api.indexTopApparatus(data).then((res) => {
        let list = res.data.data.data;
        list.forEach((item) => {
          if (!parseFloat(item.apparatusScore)) {
            item.apparatusScore = 0;
          } else {
            item.apparatusScore = parseFloat(item.apparatusScore).toFixed(1);
          }
        });
        this.topList = list;
      });
    },
    // indexTopComment() {
    //   api.indexTopComment().then((res) => {
    //     this.topList = res.data.data.data;
    //   });
    // },
    // indexTopComplain() {
    //   api.indexTopComplain().then((res) => {
    //     this.topList = res.data.data.data;
    //   });
    // },
    searchApparatus(label) {
      let data = {
        keyword: "",
      };
      let dest = [];
      return new Promise((resolve) => {
        lib.searchApparatus(data).then((res) => {
          let data = res.data.data.data;
          let listData = [];

          data.forEach((item) => {
            let list = {};

            list.value = item.apparatusId;
            list.label = item.apparatusName;
            listData.push(list);
          });

          // this.listAZ = listData;

          resolve(dest);
        });
      });
    },
    getFirm() {
      let dest = [];
      return new Promise((resolve) => {
        lib.getFirm().then((res) => {
          let data = res.data.data;

          let listData = [];
          data.forEach((item) => {
            item.data.forEach((cietm) => {
              let list = {};
              list.value = cietm.firmId;
              list.label = cietm.firmName;
              listData.push(list);
            });
          });

          this.listAZ = listData;
          console.log(this.listAZ);
          resolve(dest);
        });
      });
    },
    cascaderLazyload(node, resolve) {
      let level3 = "";
      if (node.level == 1) {
        let data = {
          firmId: node.value,
        };
        lib.getFCate(data).then((res) => {
          this.resulitData = res.data.data;

          let resultArr = [];
          this.resulitData.forEach((item) => {
            let resData = {};
            resData.label = item.catName;
            resData.value = item.catId;
            resultArr.push(resData);
          });
          resolve(resultArr);
        });
      }
      if (node.level == 2) {
        let resultArr = [];
        this.resulitData.forEach((item) => {
          if (item.catName == node.label) {
            item.children.forEach((citem) => {
              let resData = {};
              level3 = citem;
              resData.label = citem.catName;
              resData.value = citem.catId;
              resultArr.push(resData);
            });
          }
        });
        resolve(resultArr);
      }
      if (node.level == 3) {
        let resultArr = [];

        this.resulitData.forEach((item) => {
          if (item.catName == node.parent.label) {
            item.children.forEach((citem) => {
              if (citem.catName == node.label) {
                citem.children.forEach((aitem) => {
                  let resData = {};
                  resData.label = aitem.apparatusName;
                  resData.value = aitem.apparatusId;
                  resData.leaf = true;
                  this.apparatusId = resData.value;
                  resultArr.push(resData);
                });
              }
            });
          }
        });

        resolve(resultArr);
      }
      if (node.level == 4) {
      }
    },
    more() {
      this.pages++;
    },
  },
};
</script>
<style lang="scss">
.comment_count {
  .el-button {
    color: #bac0cf;
  }
}
.comment_center {
  .stars {
    .el-rate__item {
      i {
        font-size: 27px;
      }

      .el-rate__decimal {
        font-size: 27px;
      }
    }
  }
}
.complaint__l_nav-b {
  .el-button {
    border: 1px solid #0394ff;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #0394ff;
    background: #fff;
    height: 53px;
  }
  .el-cascader {
    .el-input {
      input {
        background: #f4f5f9;
        border-color: #e9e9e9;
        height: 53px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.complaint {
  display: flex;
  justify-content: space-between;
  .complaint__l {
    width: 860px;

    .complaint__l_nav {
      border-radius: 7px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      &.mt {
        margin-top: 27px;
        .complaint__l_nav-b {
          display: block;
        }
      }
      .complaint__l_nav-t {
        padding: 13px 27px;
        border-bottom: 1px solid #e9e9e9;
        .complaint__l_nav-t-ttitle {
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
      .complaint__l_nav-b {
        display: flex;
        align-items: center;

        padding: 23px 27px;
        .selct {
          display: inline-block;
          margin-right: 27px;
        }
        .comment {
          padding: 27px;
          background: #ffffff;
          .comment_tite {
            padding: 13px 0;
            font-size: 21px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
            border-bottom: 1px solid #d2d4d6;
          }
          .comment_header {
            margin-top: 27px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .username {
              display: flex;
              align-items: center;
              .user {
                margin-left: 10px;
              }
            }
            .releaseTime {
              font-size: 19px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #bac0cf;
            }
          }
          .comment_center {
            display: grid;
            grid-template-columns: 200px auto;
            grid-template-rows: auto;
            margin-top: 13px;
            .comment_center-r {
              font-size: 19px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
            .stars {
              .starOn {
                color: #ff3355;
              }
              .starOff {
                color: #dbdbdb;
              }
              .starHalf {
                color: #ff3355;
              }
              i {
                font-size: 20px;
              }
              img {
                height: 20px;
              }
            }
          }
          .comment_text {
            margin-top: 13px;
            height: 59px;
            font-size: 21px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .comment_text-more {
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #0394ff;
              cursor: pointer;
            }
          }
          .comment_img {
            display: flex;
            img {
              width: 155px;
              // min-height: 155px;
              border-radius: 7px;
            }
          }
          .comment_line {
            width: 100%;
            border-bottom: 1px solid #e9e9e9;
          }
          .comment_count {
            text-align: right;
          }
        }
      }
    }
    .button {
      margin-top: 65px;
      margin-bottom: 207px;
      text-align: center;
      button {
        padding: 17px 64px;
        outline: none;
        background: #fff;
        border: 1px solid #0394ff;
        color: #0394ff;
        border-radius: 65px;
        font-size: 21px;
        &:hover {
          background: #0394ff;
          box-shadow: 0px 5px 24px 1px
            rgba(2.9999998211860657, 147.9999303817749, 255, 0.5099999904632568);
          color: #ffffff;
        }
      }
    }
  }
  .complaint__r {
    width: 328px;
    border-radius: 7px;
    // border-top: 5px solid #0394FF;

    .topinfo__r_contanier {
      border: 1px solid #e9e9e9;
    }
    .line {
      height: 5px;
      background: #0394ff;
      border-radius: 7px 7px 0 0;
    }
    .topinfo__r_header {
      padding: 10px 19px;
      span {
        padding: 5px 0;
        border-bottom: 3px solid #0092ff;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0394ff;
      }
    }
    .topinfo__r_content {
      .content-img {
        padding: 13px 19px;
        &.top1 {
          padding: 25px 19px;
          position: relative;
          .count {
            padding: 3px 6px;
            position: absolute;
            right: 13px;
            top: 13px;
            font-size: 15px;
            border-radius: 23px;
            border: 1px solid #ffffff;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .box {
          position: relative;
          img {
            width: 100%;
            border-radius: 7px 7px 0 0;
          }
          .topIcon {
            width: 53px;
            position: absolute;
            left: -8px;
            top: -16px;
          }
        }
        .box2 {
          min-height: 88px;
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: auto auto;
          grid-column-gap: 15px;
          grid-row-gap: 15px;
          position: relative;
          img {
            width: 100%;
            border-radius: 7px;
            grid-row-start: 1;
            grid-row-end: 3;
          }
          .topIcon {
            width: 40px;
            position: absolute;
            left: -6px;
            top: -12px;
          }
          .content-title2 {
            font-size: 19px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .content-count {
            display: flex;
            align-items: flex-end;
            font-size: 16px;
            font-family: Helvetica-Regular, Helvetica;
            font-weight: 400;
            color: #ff5c5c;
            img {
              width: 35px;
              margin-right: 3px;
            }
          }
        }
      }

      .content-title {
        &.top1 {
          //   width: 217px;
          position: absolute;
          padding: 13px 21px;
          top: 50%;

          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid #ffffff;
          font-size: 19px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          border-image: linear-gradient(
              180deg,
              rgba(255, 255, 255, 1),
              rgba(255, 255, 255, 0)
            )
            1 1;
        }
      }
    }
  }
}
</style>